import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { GalleryGrid } from "../components/galleryCard"

const Gallery = () => (
  <Layout>
    <Seo title="Galerie" />
    <GalleryGrid />
  </Layout>
)

export default Gallery
