import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Collapsible from "react-collapsible"
import "../styles/gallery.css"

const graphics = [
  {
    name: "Useless",
    year: "2014",
    description: "",
    url: "../images/galerie/graphics/useless.jpg",
    tags: "Holzschnitt",
  },
  {
    name: "Das Lied vom Kapitalismus",
    year: "2016",
    description: "",
    url: "../images/galerie/graphics/das-lied-vom-kaptialismus.jpg",
    tags: "Holzschnitt",
  },
  {
    name: "Wer kennt mich?",
    year: "2016",
    description: "",
    url: "../images/galerie/graphics/wer-kennt-mich.jpg",
    tags: "Buntstift auf Papier",
  },
  {
    name: "o.T.",
    year: "2018",
    description: "",
    url: "../images/galerie/graphics/18ot.jpg",
    tags: "Kohle auf Papier",
  },
  {
    name: "Friedère de bière",
    year: "2019",
    description: "",
    url: "../images/galerie/graphics/friedere-de-biere.jpg",
    tags: "Acryl auf Papier",
  },
  {
    name: "Konzentration und Aufmerksamkeit",
    year: "2019",
    description: "",
    url: "../images/galerie/graphics/konzentration.jpg",
    tags: "Holzschnitt",
  },
  {
    name: "Sonnenopfer",
    year: "2019",
    description: "",
    url: "../images/galerie/graphics/sonnenopfer.jpg",
    tags: "Holzschnitt",
  },
]

// const instruments = [
//   {
//     name: "",
//     year: "",
//     description: "",
//     url: "",
//     tags: "",
//   },
// ]

// const plastics = [
//   {
//     name: "",
//     year: "",
//     description: "",
//     url: "",
//     tags: "",
//   },
// ]

const GalleryCardSculptures = () => {
  return (
    <Collapsible
      className="text-2xl font-bold font-courier"
      trigger="Skulptur & Plastik"
    >
      <div className="rounded shadow py-2 my-8">
        <div className="grid grid-cols-3 gap-2 m-4">
          <StaticImage
            className="col-span-2"
            src="../images/galerie/plastics/2014, Bitte fürchte mich!.JPG"
          />
          <StaticImage src="../images/galerie/plastics/2014, Kopie einer Büste Verocchios.jpg" />
          <StaticImage src="../images/galerie/plastics/2015,  !Trabajo, trabajo! -3.JPG" />
          <StaticImage src="../images/galerie/plastics/2015,  !Trabajo, trabajo!.JPG" />
          <StaticImage src="../images/galerie/plastics/2015, !Trabajo, trabajo! -2.JPG" />
          <StaticImage src="../images/galerie/plastics/2015, Ray.jpg" />
          <StaticImage src="../images/galerie/plastics/2016, AufderSchwelle.JPG" />
          <StaticImage src="../images/galerie/plastics/2016, AufderSchwelle -2.JPG" />
          <StaticImage src="../images/galerie/plastics/2016, Bauchweh.JPG" />
          <StaticImage src="../images/galerie/plastics/2016, Bauchweh-2.JPG" />
          <StaticImage src="../images/galerie/plastics/2016, Heidi.jpg" />
          <StaticImage src="../images/galerie/plastics/2016, Heidi-2.jpg" />
          <StaticImage src="../images/galerie/plastics/2016, Vale-Faun.JPG" />
          <StaticImage src="../images/galerie/plastics/2016, Vale-Faun-2.JPG" />
          <StaticImage
            className="col-span-2"
            src="../images/galerie/plastics/2017, Schweig!.jpg"
          />
          <StaticImage src="../images/galerie/plastics/2017, Spacemarine!.JPG" />
          <StaticImage src="../images/galerie/plastics/2017, Spacemarine-2.JPG" />
          <StaticImage src="../images/galerie/plastics/2017, Winfried on the rocks-2.JPG" />
          <StaticImage src="../images/galerie/plastics/2017, Winfried on the rocks-3.JPG" />
          <StaticImage
            className="col-span-2"
            src="../images/galerie/plastics/2017, Winfried on the rocks.JPG"
          />
          <StaticImage src="../images/galerie/plastics/2018, Fleur de Viande No3.JPG" />
          <StaticImage src="../images/galerie/plastics/2018, Fleur de viande No3-2.JPG" />
          <StaticImage src="../images/galerie/plastics/2018, Fleur de viande No3-3.JPG" />
          <StaticImage src="../images/galerie/plastics/2018, Jetztnichtsfalschessagen.JPG" />
          <StaticImage src="../images/galerie/plastics/2018, Leiden schafft die Leidenschaft.jpg" />
          <StaticImage src="../images/galerie/plastics/2018, Leiden schafft die Leidenschaft-2.jpg" />
          <StaticImage src="../images/galerie/plastics/2018, Leiden schafft die Leidenschaft-3.JPG" />
          <StaticImage src="../images/galerie/plastics/2018, Muse 2.0.JPG" />
          <StaticImage src="../images/galerie/plastics/2018, Muse 2.0-2.JPG" />
          <StaticImage src="../images/galerie/plastics/2018, Muse 2.0-3.JPG" />
          <StaticImage src="../images/galerie/plastics/2019, Fleur de viande No 4.JPG" />
          <StaticImage src="../images/galerie/plastics/2019, Fleur de viande No 4-2.JPG" />
          <StaticImage src="../images/galerie/plastics/2019, Miri.JPG" />
          <StaticImage
            className="col-span-2"
            src="../images/galerie/plastics/2019, Miri-2.JPG"
          />
          <StaticImage src="../images/galerie/plastics/2019, Miri-3.JPG" />
          <StaticImage src="../images/galerie/plastics/2019, Trois fleurs de viande.JPG" />
          <StaticImage src="../images/galerie/plastics/2019, Trois fleurs de viande-2.JPG" />
          <StaticImage src="../images/galerie/plastics/2020, Grantler.JPG" />
          <StaticImage
            className="col-span-3"
            src="../images/galerie/plastics/2020, Grantler-2.JPG"
          />
        </div>
      </div>
    </Collapsible>
  )
}

const GalleryCardGraphics = () => {
  return (
    <Collapsible
      className="text-2xl font-bold font-courier"
      trigger="Grafische Arbeiten"
    >
      <div className="rounded shadow py-2 my-8">
        <div className="grid grid-cols-3 gap-2 m-4">
          <StaticImage src="../images/galerie/graphics/18ot.jpg" />
          <StaticImage
            className="col-span-2"
            src="../images/galerie/graphics/das-lied-vom-kaptialismus.jpg"
          />
          <StaticImage src="../images/galerie/graphics/friedere-de-biere.jpg" />
          <StaticImage src="../images/galerie/graphics/sonnenopfer.jpg" />
          <StaticImage src="../images/galerie/graphics/useless.jpg" />
          <StaticImage
            className="col-span-2"
            src="../images/galerie/graphics/konzentration.jpg"
          />
          <StaticImage src="../images/galerie/graphics/wer-kennt-mich.jpg" />
        </div>
      </div>
    </Collapsible>
  )
}

const GalleryCardInstruments = () => {
  return (
    <Collapsible
      className="text-2xl font-bold font-courier"
      trigger="Instrumentenbau"
    >
      <div className="rounded shadow py-2 my-8">
        <hr className="m-4 " />

        <p className="m-4 px-4 text-justify text-md font-courier">
          Aus dem Hobby halb kaputte Instrumente zu retten hat sich die
          Leidenschaft entwickelt, eigene E-Gitarren zu bauen, die sich
          soundtechnisch, optisch und haptisch stark von den marktüberflutenden
          Billiginstrumenten abheben. Jede Gitarre ist ein absolutes
          Einzelstück, da ich keine Schablonen verwende und fast jeden Schritt
          in Handarbeit ausführe. Die verbaute Hardware und Elektronik ist nur
          vom Feinsten, die Leime sind auf natürlicher Basis und als Finish
          benutze ich nur Öle und Schellack. Das Instandsetzen und Umbauen von
          kaputten, alten Instrumenten bereitet mir nach wie vor viel Freude.
          Weil ich kein gelernter Instrumentenbauer bin, verkaufe ich sie als
          Klangskulpturen.
        </p>
        <hr className="m-4 pb-4 " />
        <div className="grid grid-cols-3 gap-2 m-4">
          <StaticImage
            className="col-span-2"
            src="../images/galerie/instruments/0Header-MMXX-v2.jpg"
          />
          <StaticImage src="../images/galerie/instruments/2019,Maldito-reshapedStrat.jpg" />
          <StaticImage src="../images/galerie/instruments/2019,Maldito-reshaped Strat-2.jpg" />
          <StaticImage src="../images/galerie/instruments/2020, CL - reshaped SG -2.jpg" />
          <StaticImage src="../images/galerie/instruments/2020, CL - reshaped SG -3.JPG" />
          <StaticImage src="../images/galerie/instruments/2020, CL - reshaped SG -4.JPG" />
          <StaticImage src="../images/galerie/instruments/2020, CL - reshaped SG.JPG" />
          <StaticImage src="../images/galerie/instruments/2020, MMXX.jpg" />
          <StaticImage
            className="col-span-2"
            src="../images/galerie/instruments/2020, MMXX-1.jpg"
          />
          <StaticImage src="../images/galerie/instruments/2020, MMXX-2.jpg" />
          <StaticImage src="../images/galerie/instruments/2021, Die kleine Rote -2.JPG" />
          <StaticImage src="../images/galerie/instruments/2021, Die kleine Rote -3.JPG" />
          <StaticImage src="../images/galerie/instruments/2021, Die kleine Rote -4.JPG" />
          <StaticImage src="../images/galerie/instruments/2021, Die kleine Rote.JPG" />
          <StaticImage src="../images/galerie/instruments/2021, VD II -2.JPG" />
          <StaticImage src="../images/galerie/instruments/2021, VD II -3.JPG" />
          <StaticImage src="../images/galerie/instruments/2021, VD II.JPG" />
          <StaticImage
            className="col-span-2"
            src="../images/galerie/instruments/Holz1.JPG"
          />
          <StaticImage src="../images/galerie/instruments/Holz2.JPG" />
          <StaticImage src="../images/galerie/instruments/Holz3.JPG" />
          <StaticImage src="../images/galerie/instruments/Holz4.JPG" />
        </div>
      </div>
    </Collapsible>
  )
}

const GalleryCard = ({ url }) => {
  return (
    <>
      <div className="w-1/2">
        <StaticImage
          // src={url}
          src="../images/banner/banner.jpg"
          alt="background image"
          loading="eager"
        />
      </div>
    </>
  )
}

const GalleryGrid = () => {
  return (
    <div className="grid gap-2 py-16 select-none">
      <GalleryCardSculptures />
      <GalleryCardGraphics />
      <GalleryCardInstruments />
    </div>
  )
}

export { graphics, GalleryCard, GalleryGrid }
